<template>
  <div>
    <div class="a">
      <img class="one" src="../../../assets/img/WAP产品分类5-MAN男士_02.jpg" />
    </div>
    <div class="a">
      <img class="one" src="../../../assets/img/WAP产品分类5-MAN男士_03.jpg" />
    </div>
    <div class="b">
      <img class="one" src="../../../assets/img/WAP产品分类5-MAN男士_04.jpg" />
    </div>
    <div class="e">
      <img class="one" src="../../../assets/img/WAP产品分类5-MAN男士_05.jpg" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
// .e{
//     height: 353px;
// }
// .b{
//     height: 365px;
// }
.a{
    height: 170px;
}
</style>